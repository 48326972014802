<template>
  <div class="main">
    <header-space/>
    <div class="custom-header">
      <el-steps :active="active" simple>
        <el-step :title="$t('custom.page0.工况')"></el-step>
        <el-step :title="$t('custom.page1.芯体')"></el-step>
        <el-step :title="$t('custom.page2.性能')"></el-step>
        <el-step :title="$t('custom.page3.部件')"></el-step>
        <el-step :title="$t('custom.page4.接管参数')"></el-step>
        <el-step :title="$t('custom.page5.其他')"></el-step>
        <el-step :title="$t('common.actions.confirm')"></el-step>
      </el-steps>
    </div>
    <div class="custom-content" v-if="show">
      <keep-alive>
        <component :is="page"></component>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import HeaderSpace from "@/components/HeaderSpace";
import {getGoodsCustomMode} from "@/utils";

export default {
  name: "CustomPageWrapper",
  components: {
    HeaderSpace,
    'page0': () => import('./pages/page0'),
    'page1': () => import('./pages/page1'),
    'page2': () => import('./pages/page2'),
    'page3': () => import('./pages/page3'),
    'page4': () => import('./pages/page4'),
    'page5': () => import('./pages/page5'),
    'page6': () => import('./pages/page6'),
  },
  data() {
    return {
      active: 0,
      show: true,
    }
  },
  computed: {
    page() {
      return "page" + this.active
    },
  },
  watch: {
    // 监听路由的参数变化，强制刷新设计页面重新开始，防止设计的数据冲突
    "$route.query.type"(newValue) {
      this.show = false;
      this.active = 0;
      this.$nextTick(_ => this.show = true)
	
    }
  },
  methods: {
    next() {
      this.active += 1;
    },
    prev() {
      this.active -= 1;
    },
  },
}
</script>

<style scoped lang='scss'>

.main {
  min-height: 100vh;
  background: #F5F7FA;
  height: auto;

  .custom-header {
    position: fixed;
    width: 100%;
    top: 44px;
    left: 0;
    height: 46px;
    z-index: 1;
  }

  .custom-content {
    //height: calc(100vh - 90px);
    //overflow-y: auto;
    padding-top: 44px;

    ::v-deep .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }


}

</style>